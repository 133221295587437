export const GTM_ID = process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID

declare global {
  interface Window {
    dataLayer: any
  }
}

export const pageviewGTM = (url: string) => {
  window.dataLayer.push({
    event: 'pageview',
    page: url,
  })
}

export const turboApproveLeadGTM = () => {
  window.dataLayer.push({
    event: 'turbo_approve_lead',
  })
}

export const turboApproveSubmitGTM = () => {
  window.dataLayer.push({
    event: 'turbo_approve_submit',
  })
}

export const gistOpenChat = () => {
  window.dataLayer.push({
    event: 'gist_chat_open',
  })
}

export const onPlayVideo = () => {
  window.dataLayer.push({
    event: 'video_played',
  })
}

export const videoVariationSelected = (variant) => {
  window.dataLayer.push({
    videoVariantServed: variant
  })
  window.dataLayer.push({
    event: 'video_variant_selected',
  })
}

import { getPartner } from "@/apis"
import { Partner } from "@/types/form";
import { AppStoreActions } from "@/types/store";

export const loadPartner = async ({ partner, setPartner, setPartnerLoaded, currentPartner }: { partner: string | string[], setPartner: AppStoreActions['setPartner'], setPartnerLoaded: AppStoreActions['setPartnerLoaded'], currentPartner: Partner }) => {
  if (partner && typeof partner === 'string' && partner !== currentPartner.code) {
    try {
      const { data } = await getPartner(partner)
      const loadedPartner = data.data;
      setPartner(loadedPartner)
      const customStyles = `
        .border-partner {
          border-color: ${loadedPartner.primary_hex_color};
        }
        .text-partner {
          color: ${loadedPartner.primary_hex_color};
        }
        .bg-partner {
          background-color: ${loadedPartner.primary_hex_color};
        }
      `
      const style = document.createElement('style')
      style.appendChild(document.createTextNode(customStyles))
      document.head.appendChild(style);
    } catch (e) {
    }
  }
  setPartnerLoaded(true)
}
import axios from 'axios'
import { addAlertEvent } from './events'

export const http = axios.create({
  baseURL: process.env.NEXT_PUBLIC_BASE_URL,
})

const priceFieldsKeys = [
  'purchase_price_cents',
  'property_value_cents',
  'mortgage_balance_cents',
  'cash_out_amount_cents',
  'current_mortgage_value_cents',    
  'primary_employment_monthly_income_cents',
  'other_employment_monthly_income_cents',
  'capital_gains_monthly_income_cents',
  'interest_and_dividends_monthly_income_cents',
  'real_estate_monthly_rental_income_cents',
  'retirement_monthly_income_cents',
  'disability_monthly_income_cents',
  'social_security_monthly_income_cents',
  'checking_balance_cents',
  'savings_balance_cents',
  'stocks_balance_cents',
  'retirement_balance_cents',
  'rent_monthly_payment_cents',
  'credit_cards_monthly_payment_cents',
  'auto_loans_monthly_payment_cents',
  'student_loans_monthly_payment_cents'
]

const multiplyPriceFields = (data: object) => {
  if (typeof data === 'object' && data !== null) {
    for (const [key, value] of Object.entries(data)) {
      if (typeof value === 'number' && priceFieldsKeys.includes(key)) {
        data[key] = value * 100
      } else if (typeof value === 'object' && value !== null) {
        multiplyPriceFields(value)
      }
    }
  }
}

http.interceptors.request.use(
  function (config) {
    if (config.method === 'post') {
      multiplyPriceFields(config.data)
    }

    return config
  },
  function (error) {
    return Promise.reject(error)
  }
)

http.interceptors.response.use(
  (response) => response,
  (error) => {
    if (
      Array.isArray(error?.response?.data?.data?.body) &&
      error?.response?.data?.data?.body.length
    ) {
      addAlertEvent(error.response?.data?.data?.body[0].message, 'failed')
    } else if (error?.response?.data?.message) {
      addAlertEvent(error.response?.data?.message, 'failed')
    } else {
      addAlertEvent(
        'Sorry for the inconvenience, our server is currently having issues. we are fixing it. ',
        'failed'
      )
    }

    return Promise.reject(error)
  }
)

export const httpGet = (url: string, params?: any) => {
  return http({
    method: 'GET',
    url,
    params,
  })
}

export const httpPost = (url: string, data: any) => {
  return http({
    method: 'POST',
    url,
    data,
  })
}

export const httpDelete = (url: string) => {
  return http({
    method: 'DELETE',
    url,
  })
}

export const httpPatch = (url: string, data: any) => {
  return http({
    method: 'PATCH',
    url,
    data,
  })
}

export const swrFetcher = async (url: string) => {
  try {
    const res = await axios.get(url)
    return res.data
  } catch (e) {
    throw e?.response || e
  }
}

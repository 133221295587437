import { AppStore } from '@/types/store'
import { create } from 'zustand'

const useAppStore = create<AppStore>()((set, get) => ({
  consumer_id: '',
  best_contact_time: {
    data: {
      preferred_call_day: '',
      preferred_call_time: '',
    },
    is_valid: false,
  },
  credit_check_details: {
    data: {
      ssn: '',
      date_of_birth: '',
    },
    coborrower_data: {
      ssn: '',
      date_of_birth: '',
    },
    is_valid: false,
  },
  current_address: {
    data: {
      address: '',
      years_at_address: null,
    },
    coborrower_data: {
      address: '',
      years_at_address: null,
    },
    is_valid: false,
  },
  property_details: {
    data: {
      property_city: '',
      property_occupancy_type: '' as any,
      property_state: 'TX',
      property_type: '' as any,
      purchase_price_cents: null,
      property_zipcode: '',
    },
    is_valid: false,
  },
  refinance_property_details: {
    data: {
      property_value_cents: null,
      cash_out_amount_cents: null,           
      property_city: '',
      mortgage_balance_cents: null,
      property_occupancy_type: '' as any,
      property_state: 'TX',
      property_type: '' as any,
      property_zipcode: '',
      has_mineral_rights: null,
      loan_usage_type: null
    },
    is_valid: false,
  }, 
  mortgage_details: {
    data: {
      is_first_time_homebuyer: false,
      mortgage_rate_type: 'FIXED',
      mortgage_type: 'CONVENTIONAL',
      buying_stage: 'HOME_SHOPPING',
      open_date: new Date().toISOString(),
      loan_duration_years: 30,
    },
    is_valid: true,
  },
  downpayment_details: {
    data: {
      down_payment: 0.05,
      down_payment_is_gift: false,
    },
    custom_downpayment_percentage: false,
    is_valid: false,
  },
  coborrower_contact_details: {
    data: {
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
    },
    is_valid: false,
    add_coborrower: false,
  },
  contact_details: {
    data: {
      email: '',
      phone: '',
      first_name: '',
      last_name: '',
    },
    is_valid: false,
  },
  monthly_liabilities: {
    data: {
      auto_loans_monthly_payment_cents: 0,
      credit_cards_monthly_payment_cents: 0,
      student_loans_monthly_payment_cents: 0,
      rent_monthly_payment_cents: 0,
    },
    coborrower_data: {
      auto_loans_monthly_payment_cents: 0,
      credit_cards_monthly_payment_cents: 0,
      student_loans_monthly_payment_cents: 0,
      rent_monthly_payment_cents: 0,
    },
    is_valid: false,
  },
  income_details: {
    data: {
      other_employment_monthly_income_cents: 0,
    },
    coborrower_data: {
      other_employment_monthly_income_cents: 0,
    },
    is_valid: false,
    currently_employed: false,
  },
  asset_details: {
    data: {},
    coborrower_data: {},
    is_valid: false,
  },
  alerts: [],
  partner_loaded: false,
  partner: {
    partner_id: '',
    code: '',
    email: '',
    name: '',
    logo_s3_key: '',
    logo: '',
    primary_hex_color: '',
    secondary_hex_color: '',
    website_url: '',
    contact_name: '',
    contact_title: '',
    contact_email: '',
    contact_phone: '',
    created_at: null,
  },

  //
  setConsumerId: (id) => {
    set({
      consumer_id: id,
    })
  },
  addAlert: (message, type) => {
    set({
      alerts: [
        ...get().alerts,
        {
          message,
          type,
        },
      ],
    })
  },
  removeAlert: () => {
    const alerts = [...get().alerts]
    alerts.pop()
    set({ alerts })
  },
  setContactDetails: (
    data,
    formProps = {
      is_valid: get().contact_details.is_valid,
    }
  ) => {
    set({
      contact_details: {
        data: {
          ...get().contact_details.data,
          ...data,
        },
        ...formProps,
      },
    })
  },
  setBestContactTime: (
    data,
    formProps = {
      is_valid: get().best_contact_time.is_valid,
    }
  ) => {
    set({
      best_contact_time: {
        data: {
          ...get().best_contact_time.data,
          ...data,
        },
        ...formProps,
      },
    })
  },
  setCoBorrowerContactDetails: (
    data,
    formProps = {
      is_valid: get().coborrower_contact_details.is_valid,
    }
  ) => {
    set({
      coborrower_contact_details: {
        ...get().coborrower_contact_details,
        data: {
          ...get().coborrower_contact_details.data,
          ...data,
        },
        ...formProps,
      },
    })
  },
  setAddCoBorrowerFlag: (add_coborrower: boolean) => {
    set({
      coborrower_contact_details: {
        ...get().coborrower_contact_details,
        add_coborrower,
      },
    })
  },
  setDownpaymentDetails: (
    data,
    formProps = {
      is_valid: get().downpayment_details.is_valid,
      custom_downpayment_percentage:
        get().downpayment_details.custom_downpayment_percentage,
    }
  ) => {
    set({
      downpayment_details: {
        data: {
          ...get().downpayment_details.data,
          ...data,
        },
        ...formProps,
      },
    })
  },
  setCreditCheckDetails: (
    data,
    formProps = {
      is_valid: get().credit_check_details.is_valid,
    }
  ) => {
    set({
      credit_check_details: {
        ...get().credit_check_details,
        data: {
          ...get().credit_check_details.data,
          ...data,
        },
        ...formProps,
      },
    })
  },
  setCoBorrowerCreditCheckDetails: (
    data,
    formProps = {
      is_valid: get().credit_check_details.is_valid,
    }
  ) => {
    set({
      credit_check_details: {
        ...get().credit_check_details,
        coborrower_data: {
          ...get().credit_check_details.coborrower_data,
          ...data,
        },
        ...formProps,
      },
    })
  },
  setCurrentAddress: (
    data,
    formProps = {
      is_valid: get().current_address.is_valid,
    }
  ) => {
    set({
      current_address: {
        ...get().current_address,
        data: {
          ...get().current_address.data,
          ...data,
        },
        ...formProps,
      },
    })
  },
  setCoBorrowerCurrentAddress: (
    data,
    formProps = {
      is_valid: get().current_address.is_valid,
    }
  ) => {
    set({
      current_address: {
        ...get().current_address,
        coborrower_data: {
          ...get().current_address.coborrower_data,
          ...data,
        },
        ...formProps,
      },
    })
  },
  setPropertyDetails: (
    data,
    formProps = {
      is_valid: get().property_details.is_valid,
    }
  ) => {
    set({
      property_details: {
        data: {
          ...get().property_details.data,
          ...data,
        },
        ...formProps,
      },
    })
  },
  setRefinancePropertyDetails: (
    data,
    formProps = {
      is_valid: get().refinance_property_details.is_valid,
    }
  ) => {
    set({
      refinance_property_details: {
        data: {
          ...get().refinance_property_details.data,
          ...data,
        },
        ...formProps,
      },
    })
  }, 
  setMortgageDetails: (
    data,
    formProps = {
      is_valid: get().mortgage_details.is_valid,
    }
  ) => {
    set({
      mortgage_details: {
        data: {
          ...get().mortgage_details.data,
          ...data,
        },
        ...formProps,
      },
    })
  },
  setMonthlyLiabilities: (
    data,
    formProps = {
      is_valid: get().monthly_liabilities.is_valid,
    }
  ) => {
    set({
      monthly_liabilities: {
        ...get().monthly_liabilities,
        data: {
          ...get().monthly_liabilities.data,
          ...data,
        },
        ...formProps,
      },
    })
  },
  setCoBorrowerMonthlyLiabilities: (
    data,
    formProps = {
      is_valid: get().monthly_liabilities.is_valid,
    }
  ) => {
    set({
      monthly_liabilities: {
        ...get().monthly_liabilities,
        coborrower_data: {
          ...get().monthly_liabilities.coborrower_data,
          ...data,
        },
        ...formProps,
      },
    })
  },
  setIncomeDetails: (
    data,
    formProps = {
      is_valid: get().income_details.is_valid,
      currently_employed: get().income_details.currently_employed,
    }
  ) =>
    set({
      income_details: {
        ...get().income_details,
        data: {
          ...get().income_details.data,
          ...data,
        },
        ...formProps,
      },
    }),
  setCoBorrowerIncomeDetails: (
    data,
    formProps = {
      is_valid: get().income_details.is_valid,
      currently_employed: get().income_details.currently_employed,
    }
  ) =>
    set({
      income_details: {
        ...get().income_details,
        coborrower_data: {
          ...get().income_details.coborrower_data,
          ...data,
        },
        is_valid: formProps.is_valid,
        currently_employed: get().income_details.currently_employed,
        co_borrower_currently_employed: formProps.currently_employed
      },
    }),
  setAssetDetails: (
    data,
    formProps = {
      is_valid: get().asset_details.is_valid,
    }
  ) => {
    set({
      asset_details: {
        ...get().asset_details,
        data: {
          ...get().asset_details.data,
          ...data,
        },
        ...formProps,
      },
    })
  },
  setCoBorrowerAssetDetails: (
    data,
    formProps = {
      is_valid: get().asset_details.is_valid,
    }
  ) => {
    set({
      asset_details: {
        ...get().asset_details,
        coborrower_data: {
          ...get().asset_details.coborrower_data,
          ...data,
        },
        ...formProps,
      },
    })
  },
  setPartnerLoaded: (partner_loaded) => {
    set({
      partner_loaded
    })
  },
  setPartner: (partner) => {
    set({
      partner
    })
  },
}))

export default useAppStore

import { events } from '@/constants/events';
import { AlertType } from '@/types/uikit/alert';

export const addAlertEvent = (message: string, type: AlertType) => {
  window.dispatchEvent(
    new CustomEvent(events.ADD_ALERT, {
      detail: {
        message,
        type
      }
    })
  );
};

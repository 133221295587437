import { XCircleIcon } from '@heroicons/react/24/outline'
import { CheckCircleIcon } from '@heroicons/react/20/solid'
import clsx from 'clsx'
import { Transition } from '@headlessui/react'
import { Fragment, useEffect } from 'react'
import { AlertProps } from '@/types/uikit/alert'

export default function Alert({
  type = 'success',
  message,
  onClose = () => {},
}: AlertProps) {
  const background = type === 'failed' ? 'bg-red-50' : 'bg-green-50'
  const color = type === 'failed' ? 'text-red-800' : 'text-green-800'
  const icon =
    type === 'failed' ? (
      <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
    ) : (
      <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />
    )

  useEffect(() => {
    setTimeout(() => {
      onClose()
    }, 3000)
  }, [onClose])

  return (
    <Transition
      as={Fragment}
      show={true}
      enter="transition-transform duration-150"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-150 transition ease-in-out"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div
        className={clsx(
          'mb-4 mr-16 min-w-[300px] rounded-md p-4 drop-shadow-md',
          background
        )}
      >
        <div className="flex">
          <div className="flex-shrink-0">{icon}</div>
          <div className="ml-3">
            <h3 className={clsx('text-sm font-medium', color)}>{message}</h3>
          </div>
        </div>
      </div>
    </Transition>
  )
}

import {
  CreateCredifyHomeEquityUserDto,
  CreateCredifyRefinanceUserDto,
  CreateCredifyPurchaseUserDto,
  CreateCredifyPurchaseUserResponse,
  CreateFinalCredifyRefinanceUserDto,
  CreateFinalCredifyUserDto,
  CreateFinalCredifyUserResponse,
  GetPartnerResponse,
} from '@/types/api'
import { httpGet, httpPost } from '@/utils/http'
import { AxiosPromise } from 'axios'

const axiosGetter = async <T = any>(promise: () => AxiosPromise<T>) => {
  try {
    const { data } = await promise()
    return data
  } catch (e) {
    throw e
  }
}

export const createCredifyHomeEquityUser = (
  body: CreateCredifyHomeEquityUserDto,
  query: {
    home_equity_type: 'HELOC' | 'HOME_EQUITY'
  } = { home_equity_type: 'HOME_EQUITY' }
): AxiosPromise<CreateCredifyPurchaseUserResponse> => {
  return httpPost(`/v1/consumers/homeEquityForm/start`, body)
}

export const createFinalCredifyHomeEquityUser = (
  body: CreateFinalCredifyRefinanceUserDto,
  query: {
    home_equity_type: 'HELOC' | 'HOME_EQUITY'
  } = { home_equity_type: 'HOME_EQUITY' }
): AxiosPromise<CreateCredifyPurchaseUserResponse> => {
  return httpPost(`/v1/consumers/homeEquityForm/submit`, {
    ...body,
    home_equity_type: query.home_equity_type,
  })
}

export const createCredifyRefinanceUser = (
  body: CreateCredifyRefinanceUserDto
): AxiosPromise<CreateCredifyPurchaseUserResponse> => {
  return httpPost(`/v1/consumers/refinanceForm/start`, body)
}

export const createFinalCredifyRefinanceUser = (
  body: CreateFinalCredifyRefinanceUserDto
): AxiosPromise<CreateCredifyPurchaseUserResponse> => {
  return httpPost(`/v1/consumers/refinanceForm/submit`, body)
}

export const createCredifyUser = (
  body: CreateCredifyPurchaseUserDto
): AxiosPromise<CreateCredifyPurchaseUserResponse> => {
  return httpPost(`/v1/consumers/purchaseForm/start`, body)
}

export const createFinalCredifyUser = (
  body: CreateFinalCredifyUserDto
): AxiosPromise<CreateFinalCredifyUserResponse> => {
  return httpPost(`/v1/consumers/purchaseForm/submit`, body)
}

export const getPartner = (code: string): AxiosPromise<GetPartnerResponse> => {
  return httpGet(`/v1/partners/${code}`)
}
